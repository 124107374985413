import dayjs from '@/services/dayjs';

export default {
  namespaced: true,
  state: {
    connection: null,
    authenticated: false,

    userCount: 0,
  },
  getters: {
    authenticated: (state) => state.authenticated,
    userCount: (state) => state.userCount,
  },
  mutations: {
    SOCKET_USERCOUNT(state, count) {
      console.log('usercount', count);
      const num = parseInt(count, 10);
      if (!Number.isNaN(num)) state.userCount = num;
    },
    connected(state, conn) {
      state.connection = conn;
    },
    authenticated(state) {
      state.authenticated = true;
    },
  },
  actions: {
    onConnect({ commit, dispatch, rootState }, conn) {
      console.log('socket connected', conn);
      const data = {
        userId: rootState.session.user.id,
        token: rootState.session.user.token,
        timestamp: dayjs(rootState.session.user.createdAt).unix(),
      };
      conn.emit('authenticate', data);
      commit('connected', conn);
      dispatch('site/getVersion', {}, { root: true });
    },
    socket_authenticate({ commit }, data) {
      if (data.status === 'error') {
        alert(data.error); // eslint-disable-line no-alert
        return;
      }
      commit('authenticated');
    },
    socket_sketchLiked({ dispatch }) {
      console.log('sketch liked');
      dispatch('notifications/load', {}, { root: true });
    },
    socket_sketchRemixed({ dispatch }) {
      console.log('sketch remixed');
      dispatch('notifications/load', {}, { root: true });
    },
  },
};
